import { AxiosError } from 'axios';
import { decodeArrayBufferToJson } from './decodeArrayBufferToJSON';

import { ErrorEnvelop } from '../hooks';

export const getApiErrorMessage = (error: AxiosError<ErrorEnvelop>) => {
  const { response, message: httpMessage } = error;

  let message = httpMessage;

  if (response) {
    message = response.data.error.message;
  }

  return message;
};

/**
 * Extracts the error message from an Axios error with ArrayBuffer response data
 * @param error - The Axios error object
 * @returns The extracted error message or a default message
 */
export const getApiArrayBufferErrorMessage = (error: AxiosError<ArrayBuffer>) => {
  const { response, message: httpMessage } = error;

  let message = httpMessage || 'Unknown error';

  if (response?.data instanceof ArrayBuffer) {
    try {
      const decodedJson = decodeArrayBufferToJson<ErrorEnvelop>(response.data);
      message = decodedJson?.error?.message || 'Unknown error';
    } catch (decodeError) {
      message = 'Unknown error, fail to decode response';
    }
  }

  return message;
};

export const generateUUID = (): string => {
  return crypto.randomUUID();
};
