import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Container, Row } from 'react-bootstrap';

import { FileText } from 'react-bootstrap-icons';
import './styles/styles.css';
import { termsContent } from '../../../constants/generalData';
import RenderHTMLContent from '../../../modules/utils/renderHTMLcontent';

interface UserDetailsModalProps {
  show: boolean;
  content?: string;
  handleClose: () => void;
}

export const TermsAndPrivacy: FC<UserDetailsModalProps> = ({
  show,
  content,
  handleClose,
}) => {
  const onClickSubmit = () => {
    // TODO: submit user signature
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} />
          Terms & Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              <div
                style={{
                  maxHeight: '61vh',
                  overflowY: 'auto',
                }}
              >
                <h6 className="text-white">Terms & Conditions:</h6>
                <RenderHTMLContent content={content || termsContent} />
              </div>
            </Form.Text>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button
          className="save-button no-border-radius"
          onClick={onClickSubmit}
          style={{ marginLeft: '10px' }}
        >
          Agree & Continue
        </Button>
      </Container>
    </Modal>
  );
};
