import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { UserProperties, Users } from '../../../modules/interfaces';

import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userPropertiesData, setUserPropertiesData] = useState<Users>();
  const [isValidSelectedProperty, setIsValidSelectedProperty] = useState<boolean>(true);
  const [selectedProperty, setSelectedProperty] = useState<UserProperties | undefined>();
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;

  const userPropertiesUrl = `${endpoints.USERS}/info`;
  const [{ data, loading: userPropertiesLoading, error: userPropertiesError }, getInfo] =
    useRequest<Users>(
      userPropertiesUrl,
      'get',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    if (!credentialsInfo?.token) return;

    getInfo();
  }, [credentialsInfo?.token]);

  useEffect(() => {
    if (data) {
      setUserPropertiesData(data.result);
    }
    if (userPropertiesError) {
      setSimpleToasts({
        type: 'danger',
        message: 'Error downloading agreement PDF',
        show: true,
      });
    }
  }, [data, userPropertiesError]);

  const propertiesContext = useMemo(() => {
    return {
      userPropertiesData,
      userPropertiesLoading,
      selectedProperty,
      isValidSelectedProperty,
      setSelectedProperty,
      setIsValidSelectedProperty,
      getInfo,
    };
  }, [
    userPropertiesData,
    userPropertiesLoading,
    selectedProperty,
    isValidSelectedProperty,
    setSelectedProperty,
    setIsValidSelectedProperty,
    getInfo,
  ]);

  return (
    <UserPropertiesContext.Provider value={propertiesContext}>
      {children}
    </UserPropertiesContext.Provider>
  );
};
