import { PropsWithChildren, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { routes } from '../../modules/mappers/urls';
import { UserPropertiesContext } from '../../modules/context/userPropertiesContext';

export const ProtectedRouteByProperty = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { isValidSelectedProperty } = useContext(UserPropertiesContext)!;
  const location = useLocation();
  let element = children;
  if (!isValidSelectedProperty) {
    element = <Navigate to={routes.HOME} replace state={{ from: location }} />;
  }

  return element as JSX.Element;
};
