import { createContext } from 'react';
import { RefetchFunction } from 'axios-hooks';
import { UserProperties, Users } from '../../interfaces';

export interface UserPropertiesContextProps {
  userPropertiesData?: Users;

  userPropertiesLoading: boolean;

  selectedProperty?: UserProperties;
  isValidSelectedProperty?: boolean;

  setSelectedProperty: React.Dispatch<React.SetStateAction<UserProperties | undefined>>;
  setIsValidSelectedProperty: React.Dispatch<React.SetStateAction<boolean>>;

  getInfo: RefetchFunction<unknown, unknown>;
}

export const UserPropertiesContext = createContext<
  UserPropertiesContextProps | undefined
>(undefined);
