import { FC } from 'react';
import { capitalCase } from 'case-anything';
import Modal from 'react-bootstrap/Modal';

import { Badge, Button } from 'react-bootstrap';

import { XCircle } from 'react-bootstrap-icons';

import { MewsReservation } from '../../../modules/interfaces';

interface PropertyConfigModalProps {
  selectedReservations?: MewsReservation[];

  show: boolean;
  handleClose: () => void;
  setConfirmedReservation?: (reservation: MewsReservation) => void;
}

export const paymentStatusColorMapper: Record<string, string> = {
  CONFIRMED: 'primary',
  STARTED: 'warning',
};

export const GuestReservationTableModal: FC<PropertyConfigModalProps> = ({
  selectedReservations,
  show,
  handleClose,
  setConfirmedReservation,
}) => {
  const onClose = () => {
    handleClose();
  };

  const handleBookingDetailsData = (item: MewsReservation) => {
    if (setConfirmedReservation) {
      setConfirmedReservation(item);
      handleClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      size="xl"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="h5">Guest packages table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bdr package-table-container">
          {selectedReservations && selectedReservations.length > 0 ? (
            <table className="table">
              <thead className="bg-red">
                <tr>
                  <th scope="col">
                    <div className="table-header-title">Id</div>
                  </th>
                  <th scope="col">
                    <div className="table-header-title">Start date</div>
                  </th>
                  <th scope="col">
                    <div className="table-header-title">Deperture date</div>
                  </th>

                  <th scope="col">
                    <div className="table-header-title">Status</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {selectedReservations.map((item, index) => {
                  const lastIndex = index === selectedReservations.length - 1;
                  return (
                    <tr
                      key={item?.Id || index}
                      className={lastIndex ? 'last-row-border-hide' : undefined}
                      onClick={() => handleBookingDetailsData(item)}
                    >
                      <td>
                        <div className="table-cell-text table-padding-left">
                          {item.Id}
                        </div>
                      </td>
                      <td>
                        <div className="table-cell-text">{item.ScheduledStartUtc}</div>
                      </td>
                      <td>
                        <div className="table-cell-text">{item.EndUtc}</div>
                      </td>
                      <td>
                        <div className="table-cell-text">
                          <Badge
                            className="status-badge"
                            bg={
                              item?.State
                                ? paymentStatusColorMapper[
                                    item?.State.toUpperCase() as string
                                  ]
                                : ''
                            }
                          >
                            {capitalCase(item?.State || '')}
                          </Badge>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="no-reservation-note-text">No package was selected</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button variant="secondary" onClick={onClose} className="btn-sm">
            <XCircle className="dropdown-icon me-1" />
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
