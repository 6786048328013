import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AppContext } from './modules/context/appContext';
import { ProtectedRoute } from './components/Routes';
import './App.css';
import { ForgotPassword } from './components/Forms/ForgotPassword';
import { CreateNewPassword } from './components/Forms/CreateNewPassword';
import { Layout } from './components/Layout';
import { ReservationsDashboard } from './components/Dashboards/Reservations';
import { ReservationDetails } from './components/Dashboards/ReservationDetails';
import { config } from './modules/config';
import { routes } from './modules/mappers/urls';
import { Articles } from './components/Dashboards/Articles';
import { Category } from './components/Dashboards/Categories';
import { ArticlesDetails } from './components/Forms/Articles/Details';
import { Login } from './components/Forms/Login';
import { ArticleSearch } from './components/Forms/Articles/ArticleSearch';
import { ArticleAdmin } from './components/Dashboards/Admin';
import { AccepInvite } from './components/Forms/AcceptInvite';
import { Users } from './components/Dashboards/Users';
import { Properties } from './components/Dashboards/Properties';
import { Rooms } from './components/Dashboards/Rooms';
import { InboxDashboard } from './components/Dashboards/Inbox';
import { Packages } from './components/Dashboards/Packages';
import { PackagesView } from './components/Dashboards/Packages/PackagesView';
import GuestPageLayout from './components/GuestPages/GuestPageLayout';
import { ReservationPackageProvider } from './components/Providers/ReservationPackage';
import { UserProvider } from './components/Providers/UserProperties';
import { Reports } from './components/Dashboards/Reports';
import { RevenueReports } from './components/Dashboards/RevenueReports';
import { RoomRevenueReports } from './components/Dashboards/RoomRevenueReports ';
import { UserManagement } from './components/Dashboards/UserManagement';
import { RoleManagement } from './components/Dashboards/RoleManagement';
import { UserArticles } from './components/Forms/Articles/UserArticles';
import { ArticlesProvider } from './components/Providers/Articles';
import { AllUsersProvider } from './components/Providers/AllUsers';
import { ProtectedRouteByProperty } from './components/Routes/protectedByProperty';

function App() {
  const stripePromise = loadStripe(config.stripe.publicKey);

  const initCtx = useMemo(() => {
    return { stripePromise };
  }, [stripePromise]);

  return (
    <AppContext.Provider value={initCtx}>
      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.FORGOTPASSWORD} element={<ForgotPassword />} />
        <Route path={routes.CREATENEWPASSWORD} element={<CreateNewPassword />} />
        <Route path={routes.ACCEPT_INVITE} element={<AccepInvite />} />
        <Route
          path={routes.HOME}
          element={
            <ProtectedRoute>
              <UserProvider>
                <AllUsersProvider>
                  <ArticlesProvider>
                    <ReservationPackageProvider>
                      <Layout />
                    </ReservationPackageProvider>
                  </ArticlesProvider>
                </AllUsersProvider>
              </UserProvider>
            </ProtectedRoute>
          }
        >
          <Route
            path={routes.USERS}
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PROPERTIES}
            element={
              <ProtectedRoute>
                <Properties />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ROOMS}
            element={
              <ProtectedRoute>
                <Rooms />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.RESERVATIONS}
            element={
              <ProtectedRoute>
                <ProtectedRouteByProperty>
                  <ReservationsDashboard />
                </ProtectedRouteByProperty>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.RESERVATION_DETAILS}
            element={
              <ProtectedRoute>
                <ReservationDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.INBOX}
            element={
              <ProtectedRoute>
                <ProtectedRouteByProperty>
                  <InboxDashboard />
                </ProtectedRouteByProperty>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES}
            element={
              <ProtectedRoute>
                <Articles />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.CATEGORIES}
            element={
              <ProtectedRoute>
                <Category />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES_ADMIN}
            element={
              <ProtectedRoute>
                <ArticleAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES_DETAILS}
            element={
              <ProtectedRoute>
                <ArticlesDetails subpage={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.USERS_ARTICLES}
            element={
              <ProtectedRoute>
                <UserArticles />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ARTICLES_SEARCH}
            element={
              <ProtectedRoute>
                <ArticleSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PACKAGES}
            element={
              <ProtectedRoute>
                <ProtectedRouteByProperty>
                  <Packages />
                </ProtectedRouteByProperty>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PACKAGES_MANAGEMENT}
            element={
              <ProtectedRoute>
                <PackagesView />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.REPORTS}
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.REVENUEREPORTS}
            element={
              <ProtectedRoute>
                <RevenueReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ROOMREVENUEREPORTS}
            element={
              <ProtectedRoute>
                <RoomRevenueReports />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.USERS_MANAGEMENT}
            element={
              <ProtectedRoute>
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.ROLE_MANAGEMENT}
            element={
              <ProtectedRoute>
                <RoleManagement />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={routes.GUEST_REGISTRATION} element={<GuestPageLayout />} />
        <Route path={routes.FEEDBACK} element={<GuestPageLayout />} />
        <Route path={routes.GUEST_PORTAL} element={<GuestPageLayout />} />

        <Route path="*" element={<Login />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
