import { FC, useEffect, useState, useRef, useCallback, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ICellRendererParams,
  RowClickedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import Dropdown from 'react-bootstrap/Dropdown';

import { Badge } from 'react-bootstrap';
import { capitalCase } from 'case-anything';
import { PencilSquare, CreditCard2BackFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { DataTable } from '../DataTable';
import { endpoints, routes } from '../../../modules/mappers/urls';

import { config } from '../../../modules/config';
import {
  GuestPackageResponse,
  PackageItem,
  ReservationsAPIResponse,
} from '../../../modules/interfaces';

import { UpdateRowDataModal } from '../../Modals/PackagesModals/UpdateRowDataModal';
import './styles/styles.css';

import { useAuth, useLoading, useRequest } from '../../../modules/hooks';
import { DotDropDown } from '../../Customize/DropDown';
import { PayWithAnotherCardModal } from '../../Modals/PackagesModals/PayWithAnotherCardModal';
import { ReservationPackageContext } from '../../../modules/context/guestPackageContext';
import { numberSort, roomSort } from '../../../modules/utils/tableSorts';
import { UserPropertiesContext } from '../../../modules/context/userPropertiesContext';

export const statusColorMapper: Record<string, string> = {
  APPROVED: 'primary',
  PENDING: 'warning',
  DENIED: 'danger',
  FAILED: 'danger',
};
export const paymentStatusColorMapper: Record<string, string> = {
  PAID: 'primary',
  ERROR: 'danger',
};

interface PropertyDotCellRendererProps {
  data?: PackageItem;
  onClickPay: (data?: PackageItem) => void;
  onClickEditPackageState: (data?: PackageItem) => void;
}

const PropertyDotMenu: FC<PropertyDotCellRendererProps> = ({
  data,
  onClickPay,
  onClickEditPackageState,
}) => {
  return (
    <DotDropDown title="" drop="end">
      <Dropdown.Item
        onClick={() => {
          onClickEditPackageState(data);
        }}
      >
        <PencilSquare className="dropdown-icon dropdown-icon-margin" />
        Manage Request
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          onClickPay(data);
        }}
      >
        <CreditCard2BackFill className="dropdown-icon dropdown-icon-margin" />
        Pay with card
      </Dropdown.Item>
    </DotDropDown>
  );
};

const getDotMenu = ({
  onClickPay,
  onClickEditPackageState,
}: PropertyDotCellRendererProps) => {
  const DotMenuCellRenderer: FC<ICellRendererParams<PackageItem>> = (
    params: ICellRendererParams<PackageItem>,
  ) => {
    const { data } = params;
    return (
      <PropertyDotMenu
        data={data}
        onClickPay={onClickPay}
        onClickEditPackageState={onClickEditPackageState}
      />
    );
  };

  return DotMenuCellRenderer;
};

export const BadgeCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  return (
    <Badge className="status-badge" bg={statusColorMapper[value.toUpperCase() as string]}>
      {capitalCase(value)}
    </Badge>
  );
};
export const BadgePaymentCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  return (
    <Badge
      className="status-badge"
      bg={value ? paymentStatusColorMapper[value.toUpperCase() as string] : ''}
    >
      {capitalCase(value || '')}
    </Badge>
  );
};

export const AmountCellRenderer: FC<ICellRendererParams> = (
  params: ICellRendererParams,
) => {
  const { value } = params;

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatted = Number(value).toLocaleString('en', options);

  return <span>${formatted}</span>;
};

export const PackagesTable: FC = () => {
  const gridRef = useRef<AgGridReact>(null);
  const { credentialsInfo } = useAuth()!;
  const navigate = useNavigate();
  const { setLoading } = useLoading()!;
  const { selectedProperty } = useContext(UserPropertiesContext)!;

  const [tableData, setTableData] = useState<PackageItem[]>([]);
  const [rowData, setRowData] = useState<PackageItem>();
  const [reservationID, setReservationID] = useState<string>('');
  const [showUpdateRowModal, setShowUpdateRowModal] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const reservationPackageList = useContext(ReservationPackageContext);

  const [{ data: reservationGuest, loading: loadingPreregisterd }, getReservationData] =
    useRequest<ReservationsAPIResponse>(
      endpoints.MEWS_RESERVATIONS,
      'get',
      {
        authToken: credentialsInfo?.token,
        propertyId: selectedProperty?.property.uuid,
      },
      { manual: true },
    );

  const editRow = (status: string, paymentStatus?: string) => {
    reservationPackageList?.getReservationPackage({
      params: {},
      headers: {
        authorization: credentialsInfo?.token,
      },
    });

    const newData = tableData.map(item => {
      if (item.uuid === rowData?.uuid) {
        return { ...item, status, paymentStatus };
      }
      return item;
    });
    setTableData(newData);
  };

  const parseTableDate = (dateString: string) => {
    const date = new Date(dateString);

    const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  };

  const parsePackageReservation = (arrayValue: GuestPackageResponse[]): PackageItem[] => {
    const parsedArray = arrayValue.reduce((result: PackageItem[], item) => {
      if (item.reservations.startDate && item.reservations.endDate) {
        const startDate = parseTableDate(item.reservations.startDate.toString());
        const endDate = parseTableDate(item.reservations.endDate.toString());

        result.push({
          uuid: item.uuid,
          packageId: item.packages.uuid,
          reservationId: item.reservations.externalId,
          roomName: item.reservations.roomName,
          guestName: `${item.reservations.guestFirstName} ${item.reservations.guestLastName}`,
          packageName: item.packages.name,
          arrival: startDate,
          departure: endDate,
          amount: item.amount,
          status: item.status,
          paymentStatus: item.paymentStatus || '',
          roomTypeId: item.reservations.externalRoomId,
          customerId: item.reservations.stripeId,
          reservationData: item.reservations,
        });
      }

      return result;
    }, []);

    return parsedArray;
  };

  useEffect(() => {
    if (!reservationPackageList?.reservationPackages || tableData.length !== 0) return;

    const packageData = parsePackageReservation(
      reservationPackageList?.reservationPackages,
    );
    setTableData(packageData);
  }, [reservationPackageList?.reservationPackages]);

  const onClickPay = useCallback(
    (packageItem?: PackageItem) => {
      if (packageItem) {
        setRowData(packageItem);
        setShowPayModal(true);
      }
    },
    [setRowData, setShowPayModal],
  );

  const onClickEditPackageState = useCallback(
    (packageItem?: PackageItem) => {
      if (packageItem) {
        setRowData(packageItem);
        setShowUpdateRowModal(true);
      }
    },
    [setRowData, setShowPayModal],
  );

  const [columns] = useState<ColDef<PackageItem>[]>([
    {
      field: 'reservationId',
      headerName: 'Reservation ID',
      wrapText: true,
      width: 260,
      sortable: true,
    },
    {
      field: 'packageName',
      headerName: 'Package',
      wrapText: true,
      sortable: true,
    },

    {
      field: 'roomName',
      headerName: 'Room',
      wrapText: true,
      comparator: (valueA, valueB) => roomSort(valueA, valueB),
      sortable: true,
    },
    {
      field: 'guestName',
      headerName: 'Guest Name',
      wrapText: true,
      sortable: true,
    },

    {
      field: 'arrival',
      headerName: 'Arrival',
      wrapText: true,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<PackageItem, string>) => {
        const { value } = params;
        const formattedDate = DateTime.fromFormat(value, 'yyyy/MM/dd').toFormat(
          'dd LLL yyyy',
        );

        return formattedDate;
      },
    },
    {
      field: 'departure',
      headerName: 'Departure',
      wrapText: true,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams<PackageItem, string>) => {
        const { value } = params;

        const formattedDate = DateTime.fromFormat(value, 'yyyy/MM/dd').toFormat(
          'dd LLL yyyy',
        );

        return formattedDate;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      cellRenderer: AmountCellRenderer,
      headerClass: 'text-center',
      maxWidth: 160,
      sortable: true,
      comparator: (valueA, valueB) => numberSort(valueA, valueB),
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: BadgeCellRenderer,
      maxWidth: 160,
      sortable: true,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      cellRenderer: BadgePaymentCellRenderer,
      maxWidth: 160,
      sortable: true,
    },
    {
      headerName: 'ACTION',
      colId: 'action',
      cellClass: 'actionCol justify-content-end',
      maxWidth: 120,
      cellRenderer: getDotMenu({
        onClickPay,
        onClickEditPackageState,
      }),
    },
  ]);

  useEffect(() => {
    if (!reservationGuest) return;

    const reservationData = reservationGuest.result.Reservations[0];

    const amountOfNights =
      reservationData.EndUtc && reservationData.ScheduledStartUtc
        ? DateTime.fromISO(reservationData.EndUtc)
            .startOf('day')
            .diff(
              DateTime.fromISO(reservationData.ScheduledStartUtc).startOf('day'),
              'days',
            ).days
        : 0;
    const room = reservationData.Resource
      ? `${reservationData.Resource.Name} -  ${reservationData.Resource.ResourceName}`
      : '';

    navigate(routes.RESERVATION_DETAILS.replace(':reservationId', reservationData.Id), {
      state: {
        reservationId: reservationData.Id,
        status: reservationData.State,
        phoneNumber: reservationData.Customer?.Phone || '',
        email: reservationData.Customer?.Email || '',
        guestName: `${reservationData.Customer?.FirstName} ${reservationData.Customer?.LastName}`,
        room,
        checkIn: reservationData.ScheduledStartUtc,
        checkOut: reservationData.EndUtc,
        createdAt: reservationData.CreatedUtc,
        amountOfNights,
      },
    });
  }, [reservationGuest]);

  useEffect(() => {
    setLoading(loadingPreregisterd);
  }, [loadingPreregisterd, setLoading]);

  useEffect(() => {
    if (!reservationID || !selectedProperty) return;

    getReservationData(`${endpoints.MEWS_RESERVATIONS}/${reservationID}`);
  }, [reservationID, selectedProperty]);

  return (
    <>
      <DataTable
        gridRef={gridRef}
        changeState={0}
        rowData={tableData}
        columnDefs={columns}
        pagination
        paginationPageSize={config.tables.balances.paginationSize}
        defaultColDef={{ resizable: true }}
        onRowClicked={(e: RowClickedEvent<PackageItem>) => {
          if (e.api.getFocusedCell()?.column.getColId() === 'action') {
            return;
          }

          const { data: reservation } = e;

          if (reservation) {
            const { reservationId } = reservation;
            setReservationID(reservationId);
          }
        }}
      />
      {rowData && (
        <UpdateRowDataModal
          show={showUpdateRowModal}
          handleClose={() => {
            setShowUpdateRowModal(false);
          }}
          rowData={rowData}
          onUpdate={editRow}
        />
      )}

      {rowData && (
        <PayWithAnotherCardModal
          show={showPayModal}
          paymentSuccessHandler={editRow}
          handleClose={() => {
            setShowPayModal(false);
          }}
          packageData={rowData}
        />
      )}
    </>
  );
};
