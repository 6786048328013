import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import {
  BaseFilterRequest,
  Categories,
  UserResponse,
  Users,
} from '../../../modules/interfaces';

import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';

import { endpoints } from '../../../modules/mappers/urls';

import { UsersContext } from '../../../modules/context/allUsersContext';

export const AllUsersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dataUsers, setDataUsers] = useState<Users[]>();
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;
  const [categoriesData, setCategoriesData] = useState<Categories[]>([]);
  const [filters, setFilters] = useState<BaseFilterRequest>({
    name: '',
    categories: [],
    status: [],
  });

  const [{ data: allUsers, loading: loadingUsers, error: errorUser }, getUsers] =
    useRequest<UserResponse>(
      endpoints.USERS,
      'get',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  useEffect(() => {
    if (!credentialsInfo?.token) return;

    getUsers();
  }, [credentialsInfo?.token]);

  useEffect(() => {
    if (!allUsers) return;

    setDataUsers(allUsers?.result.data);
  }, [allUsers]);

  const reservationPackageContext = useMemo(() => {
    return {
      dataUsers,
      loadingUsers,
      errorUser,
      getUsers,
    };
  }, [dataUsers, loadingUsers, errorUser, getUsers]);

  return (
    <LoadingOverlay
      active={loadingUsers && !!credentialsInfo?.token}
      text="Wait while we get things ready"
      className="loading-page-v"
    >
      <UsersContext.Provider value={reservationPackageContext}>
        {children}
      </UsersContext.Provider>
    </LoadingOverlay>
  );
};
