/**
 * Decodes an ArrayBuffer into a JSON object
 * @param arrayBuffer - The ArrayBuffer containing JSON data
 * @returns Parsed JSON object
 * @throws If decoding or parsing fails
 */
export const decodeArrayBufferToJson = <T = unknown>(arrayBuffer: ArrayBuffer): T => {
  const textDecoder = new TextDecoder('utf-8');
  const jsonString = textDecoder.decode(arrayBuffer);
  return JSON.parse(jsonString) as T;
};
