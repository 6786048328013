import React, { FC, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { FileText, Eraser } from 'react-bootstrap-icons';
import SignatureCanvas from 'react-signature-canvas';
import './styles/styles.css';
import { useFormContext } from 'react-hook-form';
import { agreementContent } from '../../../constants/generalData';
import RenderHTMLContent from '../../../modules/utils/renderHTMLcontent';

interface UserDetailsModalProps {
  show: boolean;
  content?: string;
  handleClose: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AgreementModal: FC<UserDetailsModalProps> = ({
  show,
  content,
  handleClose,
  isEnd,
}) => {
  const signCanvas = React.useRef() as React.MutableRefObject<SignatureCanvas>;
  const { setValue, getValues } = useFormContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleClear = () => {
    signCanvas.current.clear();
  };

  const onClickSubmit = () => {
    setValue('signature', signCanvas.current.toDataURL());

    if (signCanvas.current.isEmpty()) {
      setErrorMessage('Please enter your signature');
    } else {
      setErrorMessage('');
    }

    if (isEnd && !signCanvas.current.isEmpty()) {
      isEnd(true);
      handleClose();
    }
  };

  useEffect(() => {
    if (!getValues('signature')) return;
    getValues('signature');

    signCanvas.current.fromDataURL(getValues('signature'));
  }, [getValues, show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} /> {'  '}
          Agreement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              <div
                style={{
                  maxHeight: '35vh',
                  overflowY: 'auto',
                }}
              >
                <RenderHTMLContent content={content || agreementContent} />
              </div>
            </Form.Text>
          </Row>
          <Row style={{ marginTop: '20px', marginBottom: '0px' }}>
            <Col
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '12px',
              }}
            >
              <Button className="clear-button " onClick={handleClear}>
                <Eraser />
              </Button>
            </Col>
            <Col sm={12}>
              <div className="signature-canvas-container">
                <SignatureCanvas
                  canvasProps={{ className: 'signature-canvas' }}
                  ref={signCanvas}
                />
              </div>
            </Col>
            <Col
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '16px',
              }}
            >
              <span className="error-text">{errorMessage}</span>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer " style={{ marginTop: '0px' }}>
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
