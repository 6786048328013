import { FC, useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Spinner } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { useAuth, useRequestBuffer } from '../../../modules/hooks';
import { getApiArrayBufferErrorMessage } from '../../../modules/utils/transform';

// react-pdf-viewer styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './styles/styles.css';

interface PDFViewerModalProps {
  title: string;
  show: boolean;
  handleClose: () => void;
  pdfUrl: string;
  pdfDownloadName?: string;
}

const PDFViewerModal: FC<PDFViewerModalProps> = ({
  title,
  show,
  handleClose,
  pdfUrl,
  pdfDownloadName = 'document.pdf',
}) => {
  const [pdfData, setPdfData] = useState<string>();
  const [status, setStatus] = useState<'loading' | 'success' | 'error' | 'info'>(
    'loading',
  );

  const { credentialsInfo } = useAuth()!;

  const [{ data: pdfDataResponse, error: pdfDataError }] = useRequestBuffer<ArrayBuffer>(
    pdfUrl,
    'get',
    { authToken: credentialsInfo?.token },
  );

  const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i += 1) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    if (pdfDataError) {
      const errMessage = getApiArrayBufferErrorMessage(pdfDataError);

      if (errMessage === 'No guest reservation matching the provided id was found.') {
        setStatus('info');
        return;
      }

      setStatus('error');
    } else if (pdfDataResponse) {
      const base64Data = arrayBufferToBase64(pdfDataResponse);
      setPdfData(base64Data);
      setStatus('success');
    }
  }, [pdfDataResponse, pdfDataError]);

  // pdf download handler
  const handleDownload = useCallback(() => {
    if (!pdfData) return;
    const linkSource = `data:application/pdf;base64,${pdfData}`;
    const downloadLink = document.createElement('a');
    const fileName = `${pdfDownloadName}`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }, [pdfData]);

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'loading' && (
          <div className="text-center py-5">
            <Spinner animation="border" />
          </div>
        )}

        {status === 'error' && (
          <div className="text-center text-danger py-5">
            <p>Failed to load the document. Please try again later.</p>
          </div>
        )}

        {status === 'info' && (
          <div className="text-center text-muted py-5">
            <p>Agreement not signed yet.</p>
          </div>
        )}

        {status === 'success' && pdfData && (
          <div>
            <div className="pdf-viewer">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer fileUrl={`data:application/pdf;base64,${pdfData}`} />
              </Worker>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <Button variant="success" onClick={handleDownload}>
                Download
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PDFViewerModal;
