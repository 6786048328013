import { RefetchFunction } from 'axios-hooks';
import { createContext } from 'react';
import { AxiosError } from 'axios';
import { Users } from '../../interfaces';
import { ErrorEnvelop } from '../../hooks';

export interface usersContext {
  dataUsers: Users[] | undefined;
  loadingUsers: boolean;
  errorUser: AxiosError<ErrorEnvelop, unknown> | null;
  getUsers: RefetchFunction<unknown, unknown>;
}

export const UsersContext = createContext<usersContext | undefined>(undefined);
