import React from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface RenderProps {
  content: string;
}

const RenderHTMLContent: React.FC<RenderProps> = ({ content }) => {
  const sanitizedContent = DOMPurify.sanitize(content);

  const parsedContent = parse(sanitizedContent);

  return <div>{parsedContent}</div>;
};

export default RenderHTMLContent;
