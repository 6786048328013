import { FC, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { XCircle, PlusCircle, TrashFill } from 'react-bootstrap-icons';

import { PropertyNetworks } from '../../../modules/interfaces';

interface PropertyConfigModalProps {
  data?: PropertyNetworks[];
  show: boolean;
  handleClose: () => void;
  setConfirmedNetworksTable?: (networks: PropertyNetworks[]) => void;
  confirmedNetworks?: PropertyNetworks[];
}

export const PropertyNetworksModal: FC<PropertyConfigModalProps> = ({
  data: networks,
  show,
  handleClose,
  setConfirmedNetworksTable,
  confirmedNetworks,
}) => {
  const {
    register,
    reset,
    formState: { errors },
    trigger,
    watch,
    getValues,
    setValue,
  } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({
    name: 'test',
  });

  const onSubmit = async () => {
    const validate = await trigger('propertyNetworks');

    if (validate) {
      if (setConfirmedNetworksTable) {
        setConfirmedNetworksTable(getValues('propertyNetworks'));
      }
      handleClose();
    }
  };

  useEffect(() => {
    remove(0);
  }, [remove]);

  useEffect(() => {
    if (!show) {
      remove();
      return;
    }
    if (confirmedNetworks && confirmedNetworks?.length > 0) {
      replace(confirmedNetworks);
    }
  }, [networks, show, confirmedNetworks]);

  const onClose = () => {
    handleClose();

    setValue('propertyNetworks', []);

    remove();
  };

  const deleteNetworkAtIndex = (index: number) => {
    const currentNetworks = getValues('propertyNetworks') || [];

    const updatedNetworks = currentNetworks.filter((_: void, i: number) => i !== index);

    setValue('propertyNetworks', updatedNetworks);
    remove(index);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
        handleClose();
      }}
      size="xl"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="h5">Add property networks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Container
            className="table-header mt-16px"
            style={{ display: 'flex', justifyContent: 'end' }}
            fluid
          >
            <Button
              onClick={() => append({ name: '', link: '' })}
              variant="primary"
              className="ms-2 btn-sm"
            >
              <PlusCircle className="dropdown-icon me-1" />
              Add Row
            </Button>
          </Container>

          <Row className="mt-16px">
            <Col md={5}>
              <Form.Label className="editor-title-label">Name</Form.Label>
            </Col>
            <Col md={6}>
              <Form.Label className="editor-title-label">Link</Form.Label>
            </Col>
            <Col md={1} />
          </Row>

          {fields.length > 0 ? (
            fields.map((field, index) => (
              <Row key={field.id} className="mt-14px">
                <Col md={5}>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter social network name."
                    {...register(`propertyNetworks.${index}.name`, {
                      required: 'This is required.',
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`propertyNetworks.${index}.name`}
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Form.Control
                    className="editor-control"
                    placeholder="Enter social network link"
                    {...register(`propertyNetworks.${index}.link`, {
                      required: 'This is required.',
                      pattern: {
                        value:
                          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: 'The link must be a valid URL',
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`propertyNetworks.${index}.link`}
                    render={({ message }) => (
                      <span className="error-text">{message}</span>
                    )}
                  />
                </Col>
                <Col md={1}>
                  <Button
                    onClick={() => deleteNetworkAtIndex(index)}
                    className="delete-button"
                  >
                    <TrashFill size={20} />
                  </Button>
                </Col>
              </Row>
            ))
          ) : (
            <div className="no-reservation-note-text">No networks added yet</div>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button variant="secondary" onClick={onClose} className="btn-sm">
            <XCircle className="dropdown-icon me-1" />
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} variant="primary" className="ms-2 btn-sm">
            Continue
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
