import { FC, useState, useEffect, useCallback, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Overlay, Popover } from 'react-bootstrap';
import { endpoints } from '../../../modules/mappers/urls';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { AddRoleRequest, RoleResponse, Roles } from '../../../modules/interfaces';
import './styles/styles.css';
import Header from '../../Header';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import UnCheckIcon from '../../../assets/uncheck-checkbox.svg';
import CheckIcon from '../../../assets/check-checkbox.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { config } from '../../../modules/config';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { RoleTable } from '../../Tables/RoleTable';
import AddNewRole from '../../Modals/AddRole/AddRole';
import { UsersContext } from '../../../modules/context/allUsersContext';

export const RoleManagement: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [modal, setModal] = useState<boolean>(false);
  const [roleModal, setRoleModal] = useState<boolean>(false);
  const [editroleModal, setEditRoleModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string[]>(['All permissions']);
  const [statusFinal, setStatusFinal] = useState<string[]>(['All permissions']);
  const statusArray = ['All permissions', 'View', 'Edit', 'Create', 'Delete', 'Manage'];
  const [statusShow, setStatusShow] = useState(false);
  const [tableData, setTableData] = useState<Roles[]>([]);
  const [actionRole, setActionRole] = useState<Roles | undefined>();
  const { getUsers } = useContext(UsersContext)!;

  const statusTarget = useRef(null);

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data, loading, error }, refetch] = useRequest<RoleResponse>(
    endpoints.ROLES,
    'get',
    {
      authToken: credentialsInfo?.token,
      params: {
        currentPage: 0,
        pageCount: config.tables.balances.paginationSize,
      },
    },
  );

  const [{ loading: insertLoading, data: insertResult, error: insertError }, insertRole] =
    useRequest<Roles>(
      endpoints.ROLES,
      'post',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true },
    );

  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateRole] =
    useRequest<Roles>(
      `${endpoints.ROLES}/${actionRole?.uuid}`,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true, autoCancel: false },
    );

  const [{ loading: deleteLoading, data: deleteResult, error: deleteError }, deleteRole] =
    useRequest<Roles>(
      `${endpoints.ROLES}/${actionRole?.uuid}`,
      'delete',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true, autoCancel: false },
    );

  useEffect(() => {
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (deleteResult) {
      refetch();
    }
  }, [deleteResult, deleteError, setSimpleToasts, refetch]);

  useEffect(() => {
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (updateResult) {
      refetch();

      getUsers();
    }
  }, [updateResult, updateError, setSimpleToasts, refetch]);

  const refreshHandler = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (data) {
      const { result } = data;
      setTableData(result.data);
    }
  }, [data, error, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (insertResult) {
      refetch();
    }
  }, [insertResult, insertError, setSimpleToasts, refetch]);

  useEffect(() => {
    setLoading(loading || insertLoading || updateLoading || deleteLoading);
  }, [loading, insertLoading, setLoading, updateLoading, deleteLoading]);

  const onStatusItemClick = (statusItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = status.filter(item => item !== statusItem);
      setStatus(newList);
    } else {
      const newList = [...status];
      newList.push(statusItem);
      setStatus(newList);
    }
  };
  const onAddRole = () => {
    setRoleModal(true);
  };

  const renderStatusItem = (statusItem: string) => {
    const isSelected = status.includes(statusItem);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onStatusItemClick(statusItem, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{statusItem}</div>
      </div>
    );
  };

  const onCancleStatus = () => {
    setStatus(statusFinal);
    setStatusShow(false);
  };

  const onApplyStatus = () => {
    if (status.length > 0) {
      setStatusFinal(status);
      setStatusShow(false);
    }
  };

  const onAddRoleSave = (addRoleRequest: AddRoleRequest) => {
    if (editroleModal) {
      updateRole({
        data: {
          name: addRoleRequest.name,
          permissions: addRoleRequest.permissions
            .filter(item => item.value)
            .map(item => item.permission),
        },
      });
    } else {
      insertRole({
        data: {
          name: addRoleRequest.name,
          permissions: addRoleRequest.permissions
            .filter(item => item.value)
            .map(item => item.permission),
        },
      });
    }
    setEditRoleModal(false);
    setRoleModal(false);
    setActionRole(undefined);
  };

  const onRoleDelete = () => {
    deleteRole({ data: {} });
    setActionRole(undefined);
    setModal(false);
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="Role Management — 22" />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={statusTarget}
            onClick={() => setStatusShow(!statusShow)}
            className="permission-text-container"
          >
            <div className="category-text-style">
              <div className="view-text-style" style={{ marginRight: '6px' }}>
                Permissions:
              </div>
              {statusFinal[0]}
              {statusFinal.length > 1 && ' and '}
              {statusFinal.length > 1 && (
                <div className="more-text-in-dropdown">
                  +{statusFinal.length - 1} More
                </div>
              )}
            </div>
            <img
              className="drop-down-permission-img"
              src={statusShow ? DropDownOpenIcon : DropDownIcon}
              alt="drop-down"
            />
          </div>
        </div>
        <div onClick={onAddRole} className="add-artical-container">
          <img src={PlusIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Add role</div>
        </div>
      </div>
      <Overlay
        onHide={() => setStatusShow(false)}
        target={statusTarget.current}
        rootClose
        show={statusShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {statusArray.map(renderStatusItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleStatus} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyStatus} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <RoleTable
        searchString=""
        tableData={tableData}
        refreshHandler={refreshHandler}
        onEditRole={role => {
          setActionRole(role);
          setEditRoleModal(true);
        }}
        onDeleteRole={role => {
          setActionRole(role);
          setModal(true);
        }}
      />
      <AddNewRole
        actionRole={actionRole}
        show={editroleModal || roleModal}
        isEditrole={editroleModal}
        key={JSON.stringify(actionRole)}
        onSave={onAddRoleSave}
        onHide={() => {
          setEditRoleModal(false);
          setActionRole(undefined);
          setRoleModal(false);
        }}
      />
      <DeleteModal
        show={modal}
        onDelete={onRoleDelete}
        onHide={() => {
          setActionRole(undefined);
          setModal(false);
        }}
        isUserDelete
        title="Are you sure you want to delete this role?"
        description="Deleting this role will remove it from all assigned users. This action cannot be undone. Do you want to proceed?"
      />
    </Container>
  );
};
